import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import { ProductsAndAggregations, SiteProps } from '@zupr/types/next'
import { locationUrl } from '@zupr/utils/url'
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import { LocationProducts } from '@zupr/web/src/js/frontoffice/screens/location'

import { getProps } from '../../../../helpers/props'

import { inShoppingArea, redirectToShoppingArea } from '..'
import { getLocation, locationMeta } from '../../../../helpers/location'
import { getProductsAndAggregations } from '../../../../helpers/products'

interface Props extends SiteProps {
    location: Location
    productsAndAggregations: ProductsAndAggregations
}

export async function getServerSideProps({ req, params, query }) {
    const siteProps = await getProps({ req, params, query })

    const location = await getLocation({ locationId: params.locationId, ...siteProps }, req)

    if (!location) {
        return {
            notFound: true,
        }
    }

    if (!inShoppingArea(location, siteProps)) {
        return await redirectToShoppingArea(location, req)
    }

    const productsAndAggregations = await getProductsAndAggregations({
        params: {
            'product_locations.location.id': location.id,
            limit: 24,
        },
        query: siteProps.query,
    }, req)

    const meta = locationMeta({ location, ...siteProps })

    meta.title = `${t('Assortment')} | ${location.title}`

    // of location has no products skip to information page
    if (location.productsCount === 0) {
        return {
            redirect: {
                destination: locationUrl(location, 'information'),
                permanent: false,
            },
        }
    }

    return {
        props: {
            ...siteProps,
            meta,
            location,
            productsAndAggregations
        },
    }
}

const Index = ({
    location,
    productsAndAggregations,
    ...siteProps
}: Props) => (
    <Providers {...siteProps}>
        <Frontoffice>
            <LocationProducts
                location={location}
                {...productsAndAggregations}
            />
        </Frontoffice>
    </Providers>
)

export default Index
